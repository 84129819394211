// plugins/vue-kinesis.client.ts
import { defineNuxtPlugin } from "#app";
import VueKinesis from "vue-kinesis";
import { defineComponent, h } from "vue";
import useMobile from "@/composables/useMobile";

export default defineNuxtPlugin((nuxtApp) => {
	const { isMobile } = useMobile();

	// Definizione dei componenti dinamici
	const DynamicKinesisContainer = defineComponent({
		render() {
			const slotContent = this.$slots.default ? this.$slots.default() : [];

			return isMobile.value
				? h("div", {}, slotContent) // Passa slotContent come figli del div
				: h(VueKinesis.KinesisContainer, {}, slotContent); // Passa slotContent come figli di KinesisContainer
		},
	});

	const DynamicKinesisElement = defineComponent({
		render() {
			const slotContent = this.$slots.default ? this.$slots.default() : [];

			return isMobile.value
				? h("div", {}, slotContent)
				: h(VueKinesis.KinesisElement, {}, slotContent);
		},
	});

	// Registrazione dei componenti globali
	nuxtApp.vueApp.use(VueKinesis);
	nuxtApp.vueApp.component("DynamicKinesisContainer", DynamicKinesisContainer);
	nuxtApp.vueApp.component("DynamicKinesisElement", DynamicKinesisElement);
});
