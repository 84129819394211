import { default as indexCouDt8CWQnMeta } from "/home/forge/fezz.it/pages/index.vue?macro=true";
import { default as aboutapbRqBytnSMeta } from "/home/forge/fezz.it/pages/about.vue?macro=true";
import { default as blog3wJEAdzg7BMeta } from "/home/forge/fezz.it/pages/blog.vue?macro=true";
import { default as contacthyGDZbBnwhMeta } from "/home/forge/fezz.it/pages/contact.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/fezz.it/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "about___it",
    path: "/it/chi-sono",
    component: () => import("/home/forge/fezz.it/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about-me",
    component: () => import("/home/forge/fezz.it/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___de",
    path: "/de/%C3%BCber-mich",
    component: () => import("/home/forge/fezz.it/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___nl",
    path: "/nl/over-mij",
    component: () => import("/home/forge/fezz.it/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___fr",
    path: "/fr/a-propos",
    component: () => import("/home/forge/fezz.it/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___es",
    path: "/es/sobre-mi",
    component: () => import("/home/forge/fezz.it/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "blog___it",
    path: "/it/blog",
    component: () => import("/home/forge/fezz.it/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/home/forge/fezz.it/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/home/forge/fezz.it/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: "blog___nl",
    path: "/nl/blog",
    component: () => import("/home/forge/fezz.it/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    component: () => import("/home/forge/fezz.it/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/home/forge/fezz.it/pages/blog.vue").then(m => m.default || m)
  },
  {
    name: "contact___it",
    path: "/it/contatti",
    component: () => import("/home/forge/fezz.it/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/home/forge/fezz.it/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/home/forge/fezz.it/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___nl",
    path: "/nl/contact",
    component: () => import("/home/forge/fezz.it/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/home/forge/fezz.it/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___es",
    path: "/es/contacto",
    component: () => import("/home/forge/fezz.it/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it/home",
    component: () => import("/home/forge/fezz.it/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en/home",
    component: () => import("/home/forge/fezz.it/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de/startseite",
    component: () => import("/home/forge/fezz.it/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl/home",
    component: () => import("/home/forge/fezz.it/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr/accueil",
    component: () => import("/home/forge/fezz.it/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es/inicio",
    component: () => import("/home/forge/fezz.it/pages/index.vue").then(m => m.default || m)
  }
]