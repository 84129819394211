
// @ts-nocheck


export const localeCodes =  [
  "it",
  "en",
  "de",
  "nl",
  "fr",
  "es"
]

export const localeLoaders = {
  "it": [{ key: "../lang/it.ts", load: () => import("../lang/it.ts" /* webpackChunkName: "locale__home_forge_fezz_it_lang_it_ts" */), cache: true }],
  "en": [{ key: "../lang/en.ts", load: () => import("../lang/en.ts" /* webpackChunkName: "locale__home_forge_fezz_it_lang_en_ts" */), cache: true }],
  "de": [{ key: "../lang/de.ts", load: () => import("../lang/de.ts" /* webpackChunkName: "locale__home_forge_fezz_it_lang_de_ts" */), cache: true }],
  "nl": [{ key: "../lang/nl.ts", load: () => import("../lang/nl.ts" /* webpackChunkName: "locale__home_forge_fezz_it_lang_nl_ts" */), cache: true }],
  "fr": [{ key: "../lang/fr.ts", load: () => import("../lang/fr.ts" /* webpackChunkName: "locale__home_forge_fezz_it_lang_fr_ts" */), cache: true }],
  "es": [{ key: "../lang/es.ts", load: () => import("../lang/es.ts" /* webpackChunkName: "locale__home_forge_fezz_it_lang_es_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "lang": "it",
      "code": "it",
      "files": [
        "/home/forge/fezz.it/lang/it.ts"
      ],
      "dir": "ltr",
      "language": "it-IT"
    },
    {
      "lang": "en",
      "code": "en",
      "files": [
        "/home/forge/fezz.it/lang/en.ts"
      ],
      "dir": "ltr",
      "language": "en-US"
    },
    {
      "lang": "de",
      "code": "de",
      "files": [
        "/home/forge/fezz.it/lang/de.ts"
      ],
      "dir": "ltr",
      "language": "de-DE"
    },
    {
      "lang": "nl",
      "code": "nl",
      "files": [
        "/home/forge/fezz.it/lang/nl.ts"
      ],
      "dir": "ltr",
      "language": "nl-BE"
    },
    {
      "lang": "fr",
      "code": "fr",
      "files": [
        "/home/forge/fezz.it/lang/fr.ts"
      ],
      "dir": "ltr",
      "language": "fr-FR"
    },
    {
      "lang": "es",
      "code": "es",
      "files": [
        "/home/forge/fezz.it/lang/es.ts"
      ],
      "dir": "ltr",
      "language": "es-ES"
    }
  ],
  "defaultLocale": "it",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "home",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "index": {
      "it": "/home",
      "en": "/home",
      "de": "/startseite",
      "fr": "/accueil",
      "nl": "/home",
      "es": "/inicio"
    },
    "about": {
      "it": "/chi-sono",
      "en": "/about-me",
      "de": "/über-mich",
      "fr": "/a-propos",
      "nl": "/over-mij",
      "es": "/sobre-mi"
    },
    "contact": {
      "it": "/contatti",
      "en": "/contact",
      "de": "/kontakt",
      "fr": "/contact",
      "nl": "/contact",
      "es": "/contacto"
    },
    "projects": {
      "it": "/progetti",
      "en": "/projects",
      "de": "/projekte",
      "fr": "/projets",
      "nl": "/projecten",
      "es": "/proyectos"
    },
    "blog": {
      "it": "/blog",
      "en": "/blog",
      "de": "/blog",
      "fr": "/blog",
      "nl": "/blog",
      "es": "/blog"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "lang": "it",
    "code": "it",
    "files": [
      {
        "path": "/home/forge/fezz.it/lang/it.ts",
        "cache": true
      }
    ],
    "dir": "ltr",
    "language": "it-IT"
  },
  {
    "lang": "en",
    "code": "en",
    "files": [
      {
        "path": "/home/forge/fezz.it/lang/en.ts",
        "cache": true
      }
    ],
    "dir": "ltr",
    "language": "en-US"
  },
  {
    "lang": "de",
    "code": "de",
    "files": [
      {
        "path": "/home/forge/fezz.it/lang/de.ts",
        "cache": true
      }
    ],
    "dir": "ltr",
    "language": "de-DE"
  },
  {
    "lang": "nl",
    "code": "nl",
    "files": [
      {
        "path": "/home/forge/fezz.it/lang/nl.ts",
        "cache": true
      }
    ],
    "dir": "ltr",
    "language": "nl-BE"
  },
  {
    "lang": "fr",
    "code": "fr",
    "files": [
      {
        "path": "/home/forge/fezz.it/lang/fr.ts",
        "cache": true
      }
    ],
    "dir": "ltr",
    "language": "fr-FR"
  },
  {
    "lang": "es",
    "code": "es",
    "files": [
      {
        "path": "/home/forge/fezz.it/lang/es.ts",
        "cache": true
      }
    ],
    "dir": "ltr",
    "language": "es-ES"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
